import React, { useState } from "react"; // This is actually used. It's just not detected by the linter
import {
  Platform,
  Pressable,
  StyleSheet,
  View,
  TouchableOpacity,
} from "react-native";
import variables from "../../styles/variables";
import { ContentListItem } from "../../types/content";
import Icon from "../Icon";
import RText from "../RText";
// @ts-ignore
import VideoPreviewIcon from "./VideoPreviewIcon";
import ContentPreviewIcon from "./ContentPreviewIcon";
import { LoadingState } from "../States/states";
import { useResponsive } from "../../hooks/useResponsive";
import { RTextSize } from "../RText/RText";
import TermsModal from "./TermsModal";
import ShowMore from "../SectionHeader/ShowMore";
import { RootStackParamList } from "../../types/route";
import { DEFAULT_REPLAY_IMAGE } from "../Image/images/defaultImages";
import Gap from "../Gap";
import { isInternalLink } from "../Navigation/URLMapper";

const { spacing } = variables;
const isNative = Platform.OS !== "web";
const isWeb = Platform.OS === "web";

export type ContentPreviewProps = {
  thumbnailUrl?: string; // use an image as the thumnail
  thumbnailText?: string; // overlay some text on the thumbnail
  data?: any;
  description?: string;
  descriptionIcon?: string;
  descriptionFontSize?: RTextSize;
  displayAs?: "list" | "card" | "free-bet";
  icon?: "infoPink";
  style?: any;
  onPress?: () => void;
  arrowNavigatesTo?: {
    screen: keyof RootStackParamList;
    params?: any;
  };
  video?: boolean;
  handleLayout?: (event: any) => void;
  arrowText?: string;
  descriptionHeading?: string;
  isLivePreview?: boolean; // if true, show the live button on the preview
  capitalize?: boolean;
  externalHref?: string;
  inGrid?: boolean;
  loading?: "lazy" | "eager";
  alt?: string;
} & ContentListItem;

const ContentPreview: React.FC<ContentPreviewProps> = ({
  thumbnailUrl,
  thumbnailText,
  data,
  description,
  descriptionIcon,
  descriptionFontSize = "sm",
  displayAs = "card",
  icon,
  style,
  onPress,
  arrowNavigatesTo,
  video = false,
  isLoading,
  handleLayout,
  arrowText,
  descriptionHeading,
  isLivePreview = false,
  capitalize = true,
  externalHref = null,
  inGrid = false,
  loading = "lazy",
  alt = undefined,
}) => {
  const { isLargeAndUp, isMediumAndDown, isSmallAndDown } = useResponsive();
  const [openModal, setOpenModal] = useState(false);
  const displayAsList = displayAs === "list";
  const hoverRef = React.useRef(null);

  if (isLoading) return <LoadingState name="ContentPreviewLoader" />;

  const LinkWrapper = ({ children }) => {
    if (Platform.OS == "web" && !!externalHref) {
      return (
        <a
          href={externalHref}
          target={isInternalLink(externalHref) ? "_self" : "_blank"}
          style={{
            textDecoration: "none",
            ...(inGrid ? { minHeight: 200, flexBasis: 300, flexGrow: 1 } : {}),
          }}
        >
          {children}
        </a>
      );
    } else {
      return <>{children}</>;
    }
  };

  const handleMouseEnter = () => {
    if (hoverRef.current && Platform.OS === "web") {
      hoverRef.current.style.backgroundColor = variables.colors.hovered; // Example hover style
    }
  };

  const handleMouseLeave = () => {
    if (hoverRef.current && Platform.OS === "web") {
      hoverRef.current.style.backgroundColor = "white"; // Revert style
    }
  };
  return (
    <>
      <LinkWrapper>
        <TouchableOpacity
          ref={hoverRef}
          style={[
            styles.container,
            displayAsList && stylesAsList.container,
            style,
          ]} // @ts-ignore
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          // so we must re-fire on the children.
          onLayout={handleLayout}
        >
          <TouchableOpacity
            style={[
              styles.imageContainer,
              displayAsList && {
                ...stylesAsList.imageContainer,
                width: isMediumAndDown ? 128 : 160,
              },
            ]}
            onPress={onPress}
            onMouseEnter={handleMouseEnter}
          >
            {video ? (
              <VideoPreviewIcon
                thumbnailUrl={thumbnailUrl || DEFAULT_REPLAY_IMAGE}
                onPress={onPress}
                isLive={isLivePreview}
                size="small"
                loading={loading}
                alt={alt}
              />
            ) : (
              <ContentPreviewIcon
                thumbnailUrl={thumbnailUrl || DEFAULT_REPLAY_IMAGE} // @ts-ignore
                thumbnailText={thumbnailText}
                size="small"
                loading={loading}
                alt={alt}
              />
            )}
          </TouchableOpacity>
          <View
            style={[
              !displayAsList && styles.descriptionContainer,
              displayAsList && stylesAsList.descriptionContainer,
              isLargeAndUp && stylesAsList.descriptionInRow,
              displayAs == "free-bet" && { flexDirection: "row" },
            ]}
          >
            {icon && <Gap size="large" />}
            {openModal && (
              <TermsModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                data={data}
              />
            )}
            <Pressable
              style={{
                flexDirection: "column",
                justifyContent: "center",
                width: icon ? 248 : "100%",
                height: "100%",
              }}
              onPress={onPress}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                  display: "flex",
                  alignSelf: "center",
                  marginTop: 6,
                  marginVertical: 0,
                }}
                hitSlop={styles.hitSlopVertical}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    height: "100%",
                    paddingBottom:
                      Platform.OS == "web" && !displayAsList ? 6 : 0,
                  }}
                >
                  {!!descriptionIcon && (
                    <Icon
                      size="tipTag"
                      name={descriptionIcon}
                      style={{ marginRight: spacing.xxsmall }}
                      fill="palette.neutral.x0"
                    />
                  )}
                  <View
                    style={{
                      flexDirection: displayAs === "list" ? "column" : "row",
                      alignItems:
                        displayAs === "list" ? "flex-start" : "center",
                      flex: 1,
                      height: "100%",
                    }}
                  >
                    {!!descriptionHeading && (
                      <RText
                        weight="bold"
                        numberOfLines={displayAsList ? 3 : 2}
                        style={{
                          marginRight: description
                            ? variables.spacing.xsmall
                            : null,
                          minWidth: 35,
                        }}
                        size={isMediumAndDown ? "sm" : "md"}
                        lineHeight={isMediumAndDown ? 18 : 20}
                      >
                        {capitalize
                          ? descriptionHeading.capitalize()
                          : descriptionHeading}
                      </RText>
                    )}
                    <RText
                      numberOfLines={displayAsList ? 3 : 2}
                      style={styles.descriptionText}
                    >
                      {description}
                    </RText>
                  </View>
                </View>
              </View>
            </Pressable>
          </View>
          {displayAsList && (
            <View style={stylesAsList.iconContainer}>
              <ShowMore
                navigateTo={arrowNavigatesTo?.screen}
                queryParams={arrowNavigatesTo?.params}
                text={arrowText}
                style={isWeb && isSmallAndDown && { flex: 1 }}
                textStyle={{ flex: 1 }}
              />
            </View>
          )}
        </TouchableOpacity>
      </LinkWrapper>
      {!!icon && (
        <TouchableOpacity
          style={styles.iconCopy}
          onPress={() => setOpenModal(true)}
          hitSlop={styles.hitSlopSquare}
        >
          <Icon name={icon} size="large" fill="palette.rtv.secondary" />
        </TouchableOpacity>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: variables.colors.palette.neutral.x100,
    padding: variables.spacing.xxsmall,
    paddingBottom: 0,
    backgroundColor: variables.colors.palette.neutral.x0,
  },
  imageContainer: {
    aspectRatio: 16 / 9,
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionContainer: {
    height: 50,
    width: "100%",
    alignItems: "center",
  },
  description: {
    width: "100%",
    justifyContent: "center",
  },
  descriptionText: {
    ...Platform.select({
      web: {
        textWrap: "wrap",
      },
    }),
    textAlign: "left",
    flex: 1,
  },
  icon: {
    marginRight: 5,
    justifyContent: "center",
  },
  iconCopy: {
    marginRight: 5,
    justifyContent: "center",
    position: "absolute",
    bottom: 10,
    left: 5,
  },
  hitSlopSquare: {
    top: 10,
    bottom: 10,
    left: 10,
    right: 10,
  },
  hitSlopVertical: {
    top: 20,
    bottom: 20,
  },
  modal: {
    minHeight: 80,
    marginRight: "auto",
    marginLeft: "auto",
  },
  terms: {
    padding: variables.spacing.xxsmall,
  },
});

const stylesAsList = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderWidth: 0,
    borderBottomWidth: 1,
    width: "100%",
    height: isNative ? 82 : undefined,
    borderRadius: 0,
    paddingBottom: variables.spacing.xxsmall,
    alignItems: "center",
  },
  imageContainer: {
    aspectRatio: 16 / 9,
  },
  descriptionContainer: {
    display: "flex",
    justifyContent: "center",
    flex: 2,
    marginLeft: variables.spacing.xsmall,
    marginTop: 0,
  },
  iconContainer: {
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  descriptionInRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});

export default ContentPreview;
