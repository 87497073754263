import { Platform } from "react-native";
import { DeepLink, ExpoNotification } from "../types/expoNotification";

export const parseDeepLink = (notification: ExpoNotification): DeepLink => {
  let url: string | null = null;
  let trackingUrl: string | null = null;

  try {
    // Check if this is an iOS notification
    if ("payload" in notification.notification.request.trigger) {
      const iosPayload = notification.notification.request.trigger.payload;
      url = iosPayload.dd_deepLink.url;
      trackingUrl = iosPayload.dd_deepLink.trackingUrl;
    }
    // Check if this is an Android notification
    if ("remoteMessage" in notification.notification.request.trigger) {
      const androidPayload =
        notification.notification.request.trigger.remoteMessage.data
          .dd_deepLink;
      const deepLinkData = JSON.parse(androidPayload);
      url = deepLinkData.url;
      trackingUrl = deepLinkData.trackingUrl;
    }
  } catch (error) {
    console.error(`Error parsing ${Platform.OS} deep link data:`, error);
    // If parsing fails, url and trackingUrl will remain null
  }

  return { url, trackingUrl };
};
