import { UpcomingRace } from "../../components/ComingupBar/ComingupBar";
import { Meeting } from "../../types/races/meeting";
import { racesSortedByStartTime } from "../TimeCalculator";
import { isUKAndIreland } from "../isUKAndIreland";

// Takes typical meeting type and returns UpcomingRace type
export const transformUpcomingRaces = (meetings: Meeting[]): UpcomingRace[] => {
  let now = new Date();
  // First, we flatten all the races from all meetings into one array
  const allRaces = meetings.flatMap((meeting: Meeting) => {
    return meeting.races.map((race) => {
      return {
        ...race,
        icons: meeting.icons,
        meetingDate: meeting.date,
        trackName: meeting.track.name,
        trackSlug: meeting.track.slug,
        tvChannel: meeting.track.tv_channel,
        country: meeting.track.country_code.iso,
      };
    });
  });

  // Then, we sort the races by start time
  const sortedRaces = racesSortedByStartTime(allRaces);

  // Filter out the races that are not scheduled
  const scheduledRaces = sortedRaces.filter(
    (race) => race.status.state == "scheduled" && isUKAndIreland(race.country)
  );

  // Now we add the isNextRace key
  for (let idx = 0; idx < scheduledRaces.length; idx++) {
    const race = scheduledRaces[idx];
    const prevStartTime =
      idx > 0 ? new Date(scheduledRaces[idx - 1].start_time_scheduled) : null;
    const currStartTime = new Date(race.start_time_scheduled);

    if (
      (idx === 0 && currStartTime > now) ||
      (prevStartTime <= now && currStartTime > now)
    ) {
      race.isNextRace = true;
      break; // Once we find the next race, we can break the loop
    } else {
      race.isNextRace = false;
    }
  }

  return scheduledRaces;
};
