import React from "react";
import { View, StyleSheet } from "react-native";
import { cleanSlug } from "../../../../../utils/cleanSlug";
import { formatCountryCode } from "../../../../../utils/race";
import { RLink } from "../../../../RLink/RLink";
import RTouchableOpacity from "../../../../RLink/RTouchableOpacity";
import RText from "../../../../RText";
import RunnerSilk from "../../../../Racecard/RunnerSilk";
import { prepareParams } from "../../../Results/utils";
import variables from "../../../../../styles/variables";
import { Tip as TipT } from "../../../../../types/tipster";
import { useResponsive } from "../../../../../hooks/useResponsive";
import TipTeam from "./TipTeam";
import Icon from "../../../../Icon/Icon";

const { spacing } = variables;

type Props = {
  tip: TipT;
  individualTipster: boolean;
};

const TipInfo = ({ tip, individualTipster = false }: Props) => {
  const { isLargeAndUp } = useResponsive();

  return (
    <View style={{ flexDirection: "row" }}>
      <View style={{ marginRight: spacing.xsmall, marginTop: spacing.xxsmall }}>
        <RunnerSilk silk={tip.runner.silk} />
      </View>

      <View>
        <View style={{ flexDirection: "row" }}>
          <RTouchableOpacity
            navScreen="IndexOrProfile"
            navParams={{
              type: "horse",
              slug: cleanSlug(tip?.runner?.horse?.slug),
            }}
            shouldOpenInNewTab={true}
          >
            <RText weight="bold" size="md" color="primary">
              {tip.runner.horse.name}{" "}
              {tip.runner.horse?.country_code?.racing &&
                tip?.runner?.horse?.country_code.iso !== "gb" &&
                `(${formatCountryCode(tip.runner.horse.country_code.racing)})`}
            </RText>
            {individualTipster && tip.expert_eye_horse && (
              <Icon name="eye" size="small" style={styles.expertEyeIcon} />
            )}
          </RTouchableOpacity>
        </View>
        <View style={{ flexDirection: "row" }}>
          <RLink
            to={{
              screen: "IndividualRacecard",
              params: prepareParams({
                startTime: tip.runner.race.start_time_scheduled,
                track: cleanSlug(tip.runner.race.meeting.track.slug),
              }),
            }}
          >
            {`${tip.runner.race.start_time_scheduled.slice(11, 16)} ${
              tip.runner.race.meeting.track.name
            }`}
          </RLink>
        </View>

        <View style={isLargeAndUp && styles.tipTeam}>
          <TipTeam tip={tip} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tipTeam: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  expertEyeIcon: {
    marginLeft: spacing.xxsmall,
  },
});

export default TipInfo;
