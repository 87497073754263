// dot digital - to be refactored out into its own file.
import { useEffect, useRef } from "react";
import { Environment, Foundation } from "@comapi/sdk-js-foundation";
import { ComapiConfig } from "@comapi/sdk-js-foundation";
import { Buffer } from "buffer";
import { nativeNavFromSlug } from "../components/Promotion/Utils";
import { DeepLink, ExpoNotification } from "../types/expoNotification";
import { getDeviceId } from "../utils/getDeviceId";
import { parseDeepLink } from "../utils/parseDeepLink";
import {
  addNotificationResponseReceivedListener,
  getDevicePushTokenAsync,
  getPermissionsAsync,
  removeNotificationSubscription,
  requestPermissionsAsync,
} from "expo-notifications";
import { useAtom } from "jotai";
import { dotDigitalSdkAtom, userAtom } from "../atoms";
import JWT from "expo-jwt";
import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { getInitialURL, parse } from "expo-linking";
import { localStorageShim } from "../utils/localStorageShim";
import useRefreshUser from "./useRefreshUser";
import useUser from "./useUser";

global.atob = (data) => Buffer.from(data, "base64").toString("binary");
global.btoa = (binary) => Buffer.from(binary, "binary").toString("base64");

const ALG = "HS256";
const SPACE_ID = "3a277b82-c569-42da-8fa7-67ecac566971";
const SHARED_SECRET = "06794a0bcabe429985d8a985ddd69681";

// Assign your shim to global scope if the library you're adapting uses global localStorage
if (Platform.OS != "web") global.localStorage = localStorageShim;

async function registerForPushNotificationsAsync(retryCount = 5, delay = 4000) {
  let status = await getPermissionsAsync().then((res) => res.status);
  console.log("Initial status:: ", status);

  while (retryCount > 0) {
    console.log("retrying...");
    if (status !== "granted") {
      // Request permissions if not already granted
      status = await requestPermissionsAsync()
        .then((res) => res.status)
        .catch((e) => {
          console.log("Error requesting permissions:: ", e);
        });
      console.log("Requested status:: ", status);
    }

    if (status === "granted") {
      // Attempt to get the device push token
      try {
        const { data: nativeToken } = await getDevicePushTokenAsync();
        console.log("Native token:: ", nativeToken);
        return nativeToken; // Successfully obtained token, return it
      } catch (error) {
        console.error("Error obtaining native token:", error);
        // If there's an error getting the token, wait and retry
        await new Promise((resolve) => setTimeout(resolve, delay));
        retryCount--;
      }
    } else {
      console.error("Permissions not granted for push notifications.");
      retryCount--;
      // return null; // Permissions not granted, no point in retrying
    }
  }

  console.error(
    "Failed to obtain registration ID for push notifications after retries."
  );
  return null; // Failed after retries
}

/**
 * A custom hook that fetches data from a given URL but doesn't return anything.
 * It logs the fetched data to the console using ES6 syntax.
 */
const useDotDigital = () => {
  const [dotDigitalSdk, setDotDigitalSdk] = useAtom(dotDigitalSdkAtom);
  const [user, setUser] = useAtom(userAtom);
  const { refreshUser } = useRefreshUser(); // might not be necessary
  const responseListener = useRef();
  const navigation = useNavigation();

  const { isLoggedIn } = useUser();

  // utitlity to log the user's email and session state every 4 seconds so we know
  // how it moves. comment out for help debug.
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     (async function logData() {
  //       console.log("sessionState: ", dotDigitalSdk?.session?.state);
  //       const profile = await dotDigitalSdk.services.profile.getMyProfile(true);
  //       console.log("associated email :", profile?.email);
  //       // console.log("pong");
  //       console.log("user who pays:: ", user.total_cost);
  //     })();
  //   }, 4000);

  //   // Cleanup function to clear the interval
  //   return () => clearInterval(intervalId);
  // }, [dotDigitalSdk, user]); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  useEffect(() => {
    (async function updateDotDigitalProfile() {
      // return early if dotDigitalSdk is not available
      if (!dotDigitalSdk) return;

      const profile = await dotDigitalSdk.services.profile.getMyProfile();
      const isAnon = profile?.email?.startsWith("anon-");

      // console.log("********* LOGGED IN:: ", isLoggedIn);
      // console.log("********* PROFILE:: ", profile);
      // console.log("Session:: ", dotDigitalSdk?.session?.state);
      // console.log("********* IS ANON:: ", isAnon);

      // update anon contact with email
      if (isLoggedIn && isAnon) {
        // console.log(
        //   "\n\n********###**** UPDATING PROFILE WITH EMAIL FROM USEEFFECT:: ",
        //   user.detail.email,
        //   "\n\n"
        // );
        await dotDigitalSdk.current?.services.profile.updateMyProfile(
          user.detail.email
        );

        // const profile = await dotDigitalSdk.services.profile.getMyProfile(true);
        // console.log("*** EMAIL AFTER RE-FETCH email :", profile?.email);
      }
    })();
  }, [user, dotDigitalSdk]);

  // prettier-ignore
  useEffect(() => {
    if (Platform.OS === "web") return;

    const prepare = async () => {
      await refreshUser();
      const sdk = await Foundation.initialise(comapiConfig);
      setDotDigitalSdk(sdk);
      const deviceId = await getDeviceId();

      await sdk.startSession();
      const profile = await sdk.services.profile.getMyProfile();
      if (user?.detail?.email) {
        profile.email = user.detail.email;
      } else {
        profile.email = `anon-${deviceId}@yopmail.com`;
      }
      await sdk.services.profile.updateMyProfile(profile);

      const registrationId = await registerForPushNotificationsAsync();
      if (!registrationId) {
        console.error("Failed to obtain registration ID for push notifications");
        return;
      }

      if (Platform.OS === "ios") {
        try {
          await sdk.device.setAPNSPushDetails("com.racinguk.RacingUK", Environment.production, registrationId);
        } catch (error) {
          console.error("setAPNSPushDetails() failed: ", error);
        }
      } else if (Platform.OS === "android") {
        try {
          await sdk.device.setFCMPushDetails("com.racinguk.app", registrationId);
        } catch (error) {
          console.error("setFCMPushDetails() failed: ", error);
        }
      }
    };

    prepare();
  }, [user]);

  // this is used for deep links received from notifications
  useEffect(() => {
    responseListener.current = addNotificationResponseReceivedListener(
      (response: ExpoNotification) => {
        // console.log(
        //   "***************** GOT NOTIFICATION RESPONSE WHATEVER THAT IS::"
        // );
        // console.log(JSON.stringify(response, null, 2));

        const deepLink: DeepLink = parseDeepLink(response);
        // response?.notification?.request?.trigger?.payload?.dd_deepLink;

        if (deepLink) {
          // fetch the tracking url, but don't wait for it
          // console.log("##### fetching url::: ", deepLink.trackingUrl);
          fetch(deepLink.trackingUrl)
            .catch((e) => {
              // console.log("####### failed to hit tracking url:: ", e);
            })
            .finally(() => {
              // nav to deep link
              try {
                // console.log(
                //   "*** running nativeNavFromSlug with url: ",
                //   deepLink.url
                // );
                const { screen, paramsObj } = nativeNavFromSlug(deepLink.url);
                navigation?.navigate(screen, paramsObj);
              } catch (e) {
                // console.log("**** Error navigating to deep link:: ", e);
              }
            });
        } else {
          // console.log("No deep link found in notification. skipping. ");
        }
      }
    );

    // console.log("responseListener.current::", responseListener.current);

    return () => {
      removeNotificationSubscription(responseListener.current);
    };
  }, []);

  // this is used for deep links received user might tap on outside of this app
  // or any notifcations, e.g a link shared on whatsapp of the form
  // racingtv://racingtv.com/racecards/2024-01-01
  useEffect(() => {
    const handleDeepLink = (event) => {
      let data = parse(event.url);

      if (data.path) {
        const { screen, paramsObj } = nativeNavFromSlug(data.path);
        navigation?.navigate(screen, paramsObj);
      }
    };

    // Listen for incoming links
    addEventListener("url", handleDeepLink);

    // Check if the app was opened by a deep link
    getInitialURL().then((url) => {
      if (url) handleDeepLink({ url });
    });

    // Cleanup the event listener
    return () => {
      removeEventListener("url", handleDeepLink);
    };
  }, []);
};

async function challengeHandler(options, answerAuthenticationChallenge) {
  const deviceId = await getDeviceId();

  var oPayload = {
    sub: deviceId, // the customers identifier, i.e user.detail.customer_id. should be unique to a given customer
    nonce: options.nonce,
    aud: "https://api.comapi.com",
    iss: "https://api.comapi.com/defaultauth",
    iat: Date.now(),
    exp: Date.now() + 24 * 60 * 60 * 1000,
  };

  const jwt = JWT.encode(oPayload, SHARED_SECRET, { algorithm: ALG });

  answerAuthenticationChallenge(jwt);
}

let comapiConfig = new ComapiConfig()
  .withApiSpace(SPACE_ID)
  // .withLogLevel(3) // debug logs
  .withLogPersistence(0)
  .withAuthChallenge(challengeHandler);

export default useDotDigital;
