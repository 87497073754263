import React from "react"; // This is actually used. It's just not detected by the linter
import lazyImport from "../../utils/lazyImport/lazyImport";
const JsxParser = lazyImport(() => import("react-jsx-parser"));
import { View, ViewStyle, StyleSheet } from "react-native";
import {
  decodeAndCapitalize,
  removeSpacesFromJSX,
  extractScripts,
} from "./utils";
import { COLOURED_COMPONENTS, SUPPORTED_COMPONENTS } from "./components";
import { ballBreakerTest } from "./ballBreakerTest";
import { pageDataAtom, scriptsAtom } from "../../atoms";
import { useAtom } from "jotai";
import Gap from "../Gap";
import { ContentSections } from "../Screens/Members/components/ContentSections";
import RSuspense from "../../utils/RSuspense/RSuspense";
import MetaData from "../SEO/MetaData";
import { DEFAULT_PRESENTERS_IMAGE } from "../Image/images/defaultImages";

type Props = {
  content: any;
  date?: string;
  allContent?: boolean;
  textColor?: string;
  header?: boolean;
  skyscraper?: boolean;
  style?: ViewStyle;
};

const CmsContent = ({
  content,
  date,
  allContent = true,
  textColor,
  header = false,
  skyscraper = false,
  style,
}: Props) => {
  const { page } = useAtom(pageDataAtom)[0] ?? {};
  const [pageData] = useAtom(pageDataAtom);
  const [scripts, setScripts] = useAtom(scriptsAtom);
  // ** start debug debugging all content types, you can replace content
  // ** with ballBreakerTest, to make sure everything is working
  // content = ballBreakerTest;
  // ** end debug only
  if (!content) return;

  content = decodeAndCapitalize(content);

  const extractedScripts = extractScripts(content);

  extractedScripts?.length && !scripts.length && setScripts(extractedScripts);

  content = removeSpacesFromJSX(content);

  style = Array.isArray(style) ? StyleSheet.flatten(style) : style;

  return (
    <View
      style={[
        // refactor if this grows
        header
          ? { width: "100%", aspectRatio: 8.14 / 1, marginBottom: 25 }
          : {},
        skyscraper ? { width: "100%", aspectRatio: 5 / 12 } : {},
        style ? style : {},
      ]}
    >
      {allContent && !!page?.content && !!page?.content_sections.length && (
        <>
          <ContentSections sections={page?.content_sections} />
          <Gap vertical size="small" />
        </>
      )}
      <RSuspense>
        <JsxParser
          components={
            textColor ? COLOURED_COMPONENTS(textColor) : SUPPORTED_COMPONENTS
          }
          autoCloseVoidElements
          renderInWrapper={false}
          jsx={content}
        />
      </RSuspense>
      <MetaData
        title={pageData?.page?.meta_title || page?.meta_title || page?.headline}
        metaData={[
          // HTML
          { name: "description", content: page?.meta_description },
          { name: "keywords", content: page?.meta_keywords?.join(", ") },

          //twitter
          { name: "twitter:card", content: "summary" },
          { name: "twitter:site", content: "@RacingTV" },
          { name: "twitter:title", content: page?.headline },
          {
            name: "twitter:description",
            content: page?.meta_description,
          },
          {
            name: "twitter:image",
            content: `${window?.location?.origin}${DEFAULT_PRESENTERS_IMAGE}`,
          },

          //opengraph
          { name: "og:type", content: "article" },
          { name: "og:url", content: window?.location?.href },
          { name: "og:title", content: page?.headline },
          { name: "og:description", content: page?.meta_description },

          {
            name: "og:image",
            content: `${window?.location?.origin}${DEFAULT_PRESENTERS_IMAGE}`,
          },
          {
            name: "og:logo",
            content: "https://www.racingtv.com/favicon.ico",
          },
        ]}
      />
    </View>
  );
};

export default React.memo(CmsContent);
