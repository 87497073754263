import { fetchGet } from "../client";
import { useAtom } from "jotai";
import { unreadNotificationCountAtom, userAtom } from "../atoms";
import {
  getJwtToken,
  removeJwtToken,
  saveJwtToken,
} from "../components/RWebView/Utils";
import Bugsnag from "@bugsnag/expo";
import { STORAGE_KEYS, storage } from "../utils/storage";
import { getNotificationsCount } from "../client/notifications/notifications";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

const isIos = Platform.OS === "ios";

const useRefreshUser = () => {
  const [_, setUser] = useAtom(userAtom);
  const [, setUnreadNotificationCount] = useAtom(unreadNotificationCountAtom);

  const refreshUser = async (tokenOverride = null) => {
    if (tokenOverride) await saveJwtToken(tokenOverride);

    // if no token override, load user from storage, and if the user was saved in the last CACHE_TIME seconds, return it
    if (!tokenOverride) {
      const userDetails = await storage
        .load({
          key: STORAGE_KEYS.USER_DETAILS,
        })
        .catch((err) => {
          console.log(`no entry for ${STORAGE_KEYS.USER_DETAILS}`);
        });

      const unread_count = await storage
        .load({
          key: STORAGE_KEYS.UNREAD_COUNT,
        })
        .catch((err) => {
          console.log(`no entry for ${STORAGE_KEYS.UNREAD_COUNT}`);
        });

      if (userDetails && userDetails.detail) {
        console.log("returning cached user");
        // log how long we're caching this user for (on frontend)
        // const timeLeft = Math.round(
        //   (userDetails.timestamp - Date.now() + CACHE_TIME) / 1000
        // );
        // console.log("*** user cached for another " + timeLeft + " seconds");
        if (unread_count) {
          setUnreadNotificationCount(unread_count);
          isIos && Notifications.setBadgeCountAsync(unread_count);
        }
        setUser(userDetails);
        return;
      }

      if (userDetails && userDetails?.error) {
        // log user out
        await removeJwtToken();
        await storage.remove({
          key: STORAGE_KEYS.USER_DETAILS,
        });
        await storage.remove({
          key: STORAGE_KEYS.UNREAD_COUNT,
        });
        setUser({});
        return;
      }
    }

    // either use the token passed in or get the token from storage
    Promise.resolve(tokenOverride || getJwtToken())
      .then(async (token) => {
        if (token) {
          const fetchMainInfo = fetchGet("member/accounts");
          const fetchPreferences = fetchGet("member/preferences");
          const fetchUnreadNotifications = getNotificationsCount();
          const [mainInfo, preferences, unread_count] = await Promise.all([
            fetchMainInfo,
            fetchPreferences,
            fetchUnreadNotifications,
          ]);

          //  set user details in bugsnag
          Bugsnag.setUser(
            `${mainInfo?.detail?.customer_id}`,
            `${mainInfo?.detail?.email}`,
            `${mainInfo?.detail?.name_given} ${mainInfo?.detail?.name_family}`
          );
          const userDetails = {
            ...mainInfo,
            ...preferences,
            timestamp: Date.now(),
          };
          await storage.save({
            key: STORAGE_KEYS.USER_DETAILS,
            data: userDetails,
          });
          await storage.save({
            key: STORAGE_KEYS.UNREAD_COUNT,
            data: unread_count,
          });

          setUser(userDetails);
          setUnreadNotificationCount(unread_count);
          isIos && Notifications.setBadgeCountAsync(unread_count);
        }
      })
      .catch((err) => {
        console.log("autoLogin Error", err.message);
      });
  };

  return { refreshUser };
};

export default useRefreshUser;
