import { FC, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import AnimatedDropdownWrapper from "../../../Animations/DropdownAnimation";
import RText from "../../../RText/RText";
import variables from "../../../../styles/variables";
import RTextInput from "../../Account/components/RTextInput";
import { useAtom } from "jotai";
import { openAuthModalAtom, updatedHorseTrackerAtom } from "../../../../atoms";
import Icon from "../../../Icon";
import {
  deleteTrackedHorse,
  getTrackedHorsesByIds,
  updateTrackedHorse,
} from "../../../../client/tracker";
import { toastError, toastSuccess } from "../../../Toasts/Toasts";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useToast } from "react-native-toast-notifications";
import { TextLoader } from "../../../States/Loading/Loaders";
import { useRoute } from "@react-navigation/native";
import useUser from "../../../../hooks/useUser";

import { HorseTrackerType } from "../../../../types/races/horse";

type TrackedHorseCommentProps = {
  horseId: number;
  horseName: string;
  isExpanded: boolean;
  withControls: boolean;
  trackedHorse?: HorseTrackerType;
};

export const TrackedHorseComment: FC<TrackedHorseCommentProps> = ({
  horseId,
  horseName,
  isExpanded = false,
  withControls = true,
  trackedHorse = false,
}) => {
  const toast = useToast();
  const { isMediumAndUp } = useResponsive();
  // const [hasUpdated, setHasUpdated] = useAtom(updatedHorseTrackerAtom);
  const [, setAuthModalOpen] = useAtom(openAuthModalAtom);
  const [isTracked, setIsTracked] = useState(false);
  const [recieveNotifications, setReceiveNotifications] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [loading, setLoading] = useState(false);
  const route = useRoute();
  const { isLoggedIn } = useUser();

  useEffect(() => {
    (async function fetchTrackedHorse() {
      if (!isExpanded) return;
      if (!isLoggedIn) {
        setLoading(false);
        return;
      }
      try {
        if (trackedHorse || route.name === "IndividualRacecard") {
          setIsTracked(!!trackedHorse);
          setCommentInput(trackedHorse?.tracked_horse.comment || "");
          setReceiveNotifications(
            trackedHorse?.tracked_horse?.notifications || false
          );
        } else {
          const { meta, horses } = await getTrackedHorsesByIds([horseId]);
          if (meta.status !== 200) {
            throw new Error(`Tracker was not updated`);
          }

          // TODO: Create a new API endpoint to fetch a single tracked horse
          const fetchedHorse = horses[0];
          if (!fetchedHorse) return;

          setIsTracked(true);
          setCommentInput(fetchedHorse.tracked_horse.comment ?? "");
          setReceiveNotifications(fetchedHorse.tracked_horse.notifications);
        }
      } catch (error) {
        return { isTracked: false, fetchedHorse: null, error: error };
      } finally {
        setLoading(false);
      }
    })();
  }, [isExpanded, trackedHorse]);

  const handleAddTrackedHorse = async (
    id: number,
    name: string,
    comment?: string
  ) => {
    if (!isLoggedIn) {
      setAuthModalOpen("login");
      return;
    }
    try {
      const response = await updateTrackedHorse(id, comment, true);
      if (response.meta.status !== 200) {
        throw new Error(`${name} was not updated`);
      }

      setIsTracked(true);
      setReceiveNotifications(true);
      setCommentInput("");
      // setHasUpdated(true);
      toastSuccess(toast, `${name} was successfully updated`);
    } catch (error) {
      toastError(toast, `${name} was not updated`);
    }
  };

  const handleUpdateTrackedHorse = async (
    id: number,
    name: string,
    comment?: string,
    notifications?: boolean
  ) => {
    if (!isLoggedIn) {
      setAuthModalOpen("login");
      return;
    }
    try {
      const response = await updateTrackedHorse(id, comment, notifications);
      if (response.meta.status !== 200) {
        throw new Error(`${name} was not updated`);
      }

      setReceiveNotifications(notifications);
      // setHasUpdated(true);
      toastSuccess(toast, `${name} was successfully updated`);
    } catch (error) {
      toastError(toast, `${name} was not updated`);
    }
  };

  const handleDeleteTrackedHorse = async (id: number, name: string) => {
    if (!isLoggedIn) {
      setAuthModalOpen("login");
      return;
    }
    try {
      const response = await deleteTrackedHorse(id);
      if (response.meta.status !== 200) {
        throw new Error(`${name} was not deleted`);
      }

      setIsTracked(false);
      setReceiveNotifications(false);
      setCommentInput("");

      // setHasUpdated(true);
      toastSuccess(toast, `${name} was successfully deleted`);
    } catch (error) {
      toastError(toast, `${name} was not deleted`);
    }
  };

  return (
    <AnimatedDropdownWrapper open={isExpanded}>
      {loading ? (
        <View
          style={[
            styles.commentContainer,
            isTracked ? styles.trackedComment : styles.notTrackedComment,
            {
              height: isExpanded ? 100 : 0,
              marginTop: isExpanded ? 5 : 0,
            },
          ]}
        >
          <TextLoader height={isExpanded ? 100 : 0} />
        </View>
      ) : (
        <View
          style={[
            styles.commentContainer,
            isTracked ? styles.trackedComment : styles.notTrackedComment,
            {
              height: isExpanded ? 100 : 0,
              marginTop: isExpanded ? 5 : 0,
            },
          ]}
        >
          <RTextInput
            keyboardType="default"
            placeholder="Tracker comment"
            onChangeText={(e) => setCommentInput(e)}
            value={commentInput}
            style={styles.comment}
            onBlur={() => null}
            isSecure={false}
            multiline
          />

          <View
            style={[
              styles.commentCtas,
              {
                flexDirection: isMediumAndUp ? "row" : "column-reverse",
                alignItems: isMediumAndUp ? "flex-start" : "flex-end",
                justifyContent: isMediumAndUp ? "flex-end" : "space-between",
                padding: 10,
              },
            ]}
          >
            <TouchableOpacity
              disabled={commentInput?.length === 0}
              activeOpacity={0.6}
              style={{
                marginRight: isMediumAndUp ? 10 : 0,
              }}
              onPress={() =>
                isTracked
                  ? handleUpdateTrackedHorse(
                      horseId,
                      horseName,
                      commentInput,
                      recieveNotifications
                    )
                  : handleAddTrackedHorse(horseId, horseName, commentInput)
              }
            >
              <RText
                capitalize
                color={commentInput?.length === 0 ? "grey" : "secondary"}
                weight="bold"
              >
                save
              </RText>
            </TouchableOpacity>

            {withControls && (
              <TouchableOpacity
                activeOpacity={0.6}
                style={{
                  marginRight: isMediumAndUp ? 10 : 0,
                }}
                onPress={() =>
                  isTracked
                    ? handleUpdateTrackedHorse(
                        horseId,
                        horseName,
                        commentInput,
                        !recieveNotifications
                      )
                    : handleAddTrackedHorse(horseId, horseName)
                }
              >
                <Icon
                  name={
                    recieveNotifications
                      ? "notificationSelected"
                      : "notificationUnselected"
                  }
                  size="medium"
                />
              </TouchableOpacity>
            )}

            {withControls && (
              <TouchableOpacity
                activeOpacity={0.6}
                onPress={() =>
                  isTracked
                    ? handleDeleteTrackedHorse(horseId, horseName)
                    : handleAddTrackedHorse(horseId, horseName, commentInput)
                }
              >
                <Icon
                  name={
                    isTracked ? "binocularsSelected" : "binocularsUnselected"
                  }
                  size="medium"
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}
    </AnimatedDropdownWrapper>
  );
};

const { colors } = variables;
const styles = StyleSheet.create({
  commentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    borderColor: colors.palette.neutral.x200,
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: colors.palette.transparent.secondary,
    marginBottom: 10,
  },
  comment: {
    width: "85%",
  },
  trackedComment: {
    backgroundColor: colors.palette.rtv.tracker,
  },
  notTrackedComment: {
    backgroundColor: "white",
  },
  commentCtas: {
    width: "15%",
  },
});
