import {
  // View,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Platform,
  // Easing,
} from "react-native";
import React, { useLayoutEffect, useState } from "react";
import variables from "../../styles/variables";
import RText from "../RText/RText";
import Icon from "../Icon";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import { useAtom } from "jotai";
import { tabbarHeightAtom, unreadNotificationCountAtom } from "../../atoms";

type Props = {
  currentRoute: string;
  linking: any;
  tabBarHeightValue;
};

const TabBar = ({ currentRoute, linking, tabBarHeightValue }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>();
  const navigation = useNavigation();

  const [unreadCount] = useAtom(unreadNotificationCountAtom);
  const [_, setTabbarHeight] = useAtom(tabbarHeightAtom);

  const tabScreens = [
    { title: "Races", route: "Racecards", topDirectory: "racecards" },
    { title: "Tips", route: "RTVTipsters", topDirectory: "tips" },
    { title: "Watch", route: "Watch", topDirectory: "watch" },
    { title: "Results", route: "Results", topDirectory: "results" },
    { title: "Menu", route: "Menu", topDirectory: "menu" },
  ];

  // currentRoute is the key in urlmapper - not explicitly linked to the url.
  // so we need to get the path from the urlmapper
  const path =
    linking.config.screens[currentRoute]?.path ||
    linking.config.screens[currentRoute];

  // and then the top most directory, so we can stay selected even on sub pages
  const topDirectory = path?.split("/")[1];

  useLayoutEffect(() => {
    if (selectedTab != topDirectory) setSelectedTab(topDirectory);
  }, [topDirectory]);

  return (
    <>
      <Animated.View
        onLayout={(e) => setTabbarHeight(e.nativeEvent.layout.height)}
        style={[
          styles.tabBarContainer,
          {
            height:
              Platform.OS == "ios"
                ? tabBarHeightValue._value + 10
                : tabBarHeightValue._value,
            overflow: "hidden",
          },
        ]}
      >
        {tabScreens.map((tab) => {
          const isSelectedTab = tab.topDirectory === selectedTab;
          return (
            <TouchableOpacity
              accessibilityLabel={`TabBar-${tab.title}`}
              key={tab.title}
              onPress={() => {
                if (tab.title === "Menu") {
                  navigation.dispatch(DrawerActions.toggleDrawer());
                } else {
                  setSelectedTab(tab.topDirectory);
                  navigation.navigate(tab.route);
                }
              }}
              style={styles.tabButton}
            >
              <Icon
                name={
                  isSelectedTab
                    ? tab.title.toLowerCase() + "Selected"
                    : tab.title.toLowerCase()
                }
                size="tabBarIcons"
                notifications={tab.title === "Menu" && unreadCount}
                style={{ minHeight: 30 }}
                onTabar
              />
              <RText
                style={
                  isSelectedTab
                    ? styles.tabButtonTextSelected
                    : styles.tabButtonText
                }
                color="white"
              >
                {tab.title}
              </RText>
            </TouchableOpacity>
          );
        })}
      </Animated.View>
    </>
  );
};

const { colors } = variables;

const styles = StyleSheet.create({
  tabBarContainer: {
    width: "100%",
    backgroundColor: colors.palette.rtv.primary,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  tabButton: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 30,
    ...Platform.select({
      ios: {
        paddingBottom: 10, // avoids the system controls, not necessary on android
      },
    }),
  },
  tabButtonText: {
    textTransform: "capitalize",
  },
  tabButtonTextSelected: {
    color: colors.palette.neutral.x500,
  },
});

export default TabBar;
