import React, { useState, useEffect } from "react";
import { Image, Dimensions, View, Text } from "react-native";

type Props = {
  id: string;
  metadata: any;
  caption?: string;
};

const MediaAsset = ({ id, metadata, caption }: Props) => {
  const [imageWidth, setImageWidth] = useState(500); //Dimensions.get("window").width);
  const [imageHeight, setImageHeight] = useState(300); // default height

  const handleImageLoaded = (event) => {
    if (!event.nativeEvent.source) return;
    const width = event.nativeEvent.source.width;
    const height = event.nativeEvent.source.height;
    if (width && height) {
      const aspectRatio = height / width;
      setImageHeight(imageWidth * aspectRatio);
    }
  };

  return (
    <>
      <Image
        source={{
          uri:
            metadata?.viewer_sources && metadata?.viewer_sources.length
              ? metadata?.viewer_sources[0]?.url
              : metadata?.placeholder_image_url || null,
        }}
        style={{
          width: imageWidth,
          minWidth: "100%",
          maxWidth: "100%",
          aspectRatio: 16 / 9,
          // maxHeight: imageHeight, <-- the images look better without this on benefits pages, see rewards4racing.
          backgroundColor: "#f2f2f2",
        }}
        onLoad={handleImageLoaded}
        resizeMode="contain"
      />
      {caption && <Text>{caption || "caption"}</Text>}
    </>
  );
};

export default React.memo(MediaAsset);
