import { YYYYMMDD, today } from "../../utils/TimeCalculator";
import { Screens } from "../Navigation/URLMapper";

// get the date for the tabs
export function dateLabelInTwoDaysPlus(date: Date, ahead = 0) {
  const todaysDate = date.getDay(); //|| new Date("2022-11-25").getDay(); // TODO: Remove dates so this is from today.
  //       rarely data here so fixed it to a date
  const dayAfterTomorrow = (todaysDate + 1 + ahead) % 7; // loop back to monday with modulo
  return {
    0: "monday",
    1: "tuesday",
    2: "wednesday",
    3: "thursday",
    4: "friday",
    5: "saturday",
    6: "sunday",
  }[dayAfterTomorrow];
}

export type Weekday =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

// days of the week array
export const daysOfTheWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

// generates dates and labels for the last 7 days
export const lastSevenDays = Array.from({ length: 7 }, (_, i) =>
  datePlus(new Date(), -i)
);

export const nextSevenDays = Array.from({ length: 7 }, (_, i) =>
  datePlus(new Date(), i)
);

export const labelsIndexToLabel = () => {
  return {
    0: "today",
    1: "yesterday",
    2: daysOfTheWeek[lastSevenDays[2].getDay()],
    3: daysOfTheWeek[lastSevenDays[3].getDay()],
    4: daysOfTheWeek[lastSevenDays[4].getDay()],
    5: daysOfTheWeek[lastSevenDays[5].getDay()],
    6: daysOfTheWeek[lastSevenDays[6].getDay()],
  };
};

export const dateLabels = {
  [YYYYMMDD(today)]: 0, // 2023-01-01 is a sunday - this treats it as today
  [YYYYMMDD(datePlus(today, 1))]: 1, // 2023-01-02 is a monday - this treats it as tomorrow
  [YYYYMMDD(datePlus(today, 2))]: 2, // 2023-01-03 is a tuesday - this treats it as the label for 2 days time -
  // but it should be the label for the date - which is possibly not in the list
  // the only solution is to redraw the labels when yyyy-mm-dd dates are in play
  [YYYYMMDD(datePlus(today, 3))]: 3,
  [YYYYMMDD(datePlus(today, 4))]: 4,
  [YYYYMMDD(datePlus(today, 5))]: 5,
  [YYYYMMDD(datePlus(today, 6))]: 6,
  // undefined: 0,
};

export const prevDateLabels = {
  [YYYYMMDD(today)]: 0,
  [YYYYMMDD(datePlus(today, -1))]: 1,
  [YYYYMMDD(datePlus(today, -2))]: 2,
  [YYYYMMDD(datePlus(today, -3))]: 3,
  [YYYYMMDD(datePlus(today, -4))]: 4,
  [YYYYMMDD(datePlus(today, -5))]: 5,
  [YYYYMMDD(datePlus(today, -6))]: 6,
};

// determines if parameter is either today, tomorrow, monday tuesday wednesday thursday friday saturday or sunday
export const isFriendlyDate = (input) =>
  [
    "today",
    "yesterday",
    "tomorrow",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].includes(input?.toLowerCase());

export const isYYYYMMDD = (input) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(input);
};

export function datePlus(date: Date, ahead = 1): Date {
  // We have to clone the date (by wrapping in new Date()) otherwise we mutate the original date
  let targetDay = new Date(date) || new Date("2022-11-25");
  targetDay.setDate(targetDay.getDate() + ahead);

  return targetDay;
}

const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const updateLocale = require("dayjs/plugin/updateLocale");
dayjs.extend(updateLocale);

// dayjs.extend(updateLocale)

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

export const timeDifference = (date: string) => dayjs(date).fromNow();

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export const dateToString = (date: Date) => dayjs(date).format("DD/MM/YYYY");

export const formatDateWithFullStops = (date: string) =>
  dayjs(date).format(`DD.MM.YYYY`);

export const formatDateWithDay = (date: string) => dayjs(date).format(`dddd`);

export const formatDateWithDashes = (date: string) =>
  dayjs(date, "DD/MM/YYYY" || "DD.MM.YYYY").format("DD-MM-YYYY");

const validateIsDate = (date: string) =>
  isFriendlyDate(date) || isYYYYMMDD(date);

const validateTrackerTabs = (tabName: string) =>
  ["entries", "horses", "preferences"].includes(tabName);

const validateAccountTabs = (tabName: string) =>
  ["details", "preferences", "sky-subscription", "notifications"].includes(
    tabName
  );

const validateFestivalHub = (tabName: string) =>
  [
    "overview",
    "racecards",
    "tips",
    "news",
    "replays",
    "results",
    "free-bets",
  ].includes(tabName);

const validateFestivalIndex = (tabName: string) =>
  ["day-1", "day-2", "day-3", "day-4", "day-5", "day-6", "day-7"].includes(
    tabName
  );

const validateRacecourse = (tabName: string) =>
  ["overview", "racecards", "tips", "results"].includes(tabName);

const validateRegion = (region: string) =>
  ["racing-tv", "uk", "ireland", "international"].includes(region);

const validateProfile = (tabName: string) =>
  ["horse", "jockey", "trainer"].includes(tabName);

const excludedRoutes: Screens[] = [
  "IndividualRacecard", // raceMatrix only sits on this page, so we don't need to add exceptions for RaceMatrix.
  "IndividualOnDemand",
  "CombinedOnDemand",
];

// these routes are also excluded from being href tabs, but only if a condition is met
// which is useful when there's multiple sets of tabs on the same screen, e.g.
// we want href links on /profiles/horse but not on /profiles/horse/abc
const excludedOnlyForCondition: any[] = [
  {
    name: "IndexOrProfile",
    condidtion: (params: Object) => params?.slug?.length > 1,
  },
];

// these need a corresponding redirect in _redirects, to take us from say /racecards/today to /racecards
const includedRoutes: Partial<Record<Screens, Object>> = {
  CatchUp: { key: "date", default: "today", validate: validateIsDate },
  Racecards: { key: "date", default: "today", validate: validateIsDate },
  Results: { key: "date", default: "today", validate: validateIsDate },
  RTVTipsters: { key: "date", default: "today", validate: validateIsDate },
  IndividualTipster: {
    key: "date",
    default: "today",
    validate: validateIsDate,
  },
  AllTips: { key: "tabName", default: "today", validate: validateIsDate },
  Tracker: {
    key: "tabName",
    default: "entries",
    validate: validateTrackerTabs,
  },
  Account: {
    key: "tabName",
    default: "details",
    validate: validateAccountTabs,
  },
  Racecourses: {
    key: "region",
    default: "racing tv",
    validate: validateRegion,
  },
  Racecourse: {
    key: "tabName",
    default: "overview",
    validate: validateRacecourse,
  },
  Replays: { key: "date", default: "today", validate: validateIsDate },
  TvSchedule: { key: "date", default: "today", validate: validateIsDate },
  // these two hub routes won't have a redirect covered at cloudflare level
  // because we can't match on the index route (everything would match)
  FestivalHub: {
    key: "tabName",
    default: "overview",
    validate: validateFestivalHub,
  },
  FestivalIndex: {
    key: "day",
    default: "day-1",
    validate: validateFestivalIndex,
  },
  IndexOrProfile: {
    key: "type",
    validate: validateProfile,
  },
};

export const buildTabNavParams = (route: any, tabName: string) => {
  // so we need to include most routes, some with different tab names (i.e tabName, section, date based tabs).
  // some we need to exclude because
  //   - they have multiple tabs (so we don't know which url to set)
  //   - they are inside a popup (i.e raceMatrix), so we don't want to update any urls.
  // generally, if a tab forms its own standalone page (i.e my-account/preferences or /racecards/2023-01-01) then we can should the url.
  // if the tab does not form a standalone page (i.e tab is inside a component or there's multiple tabs on a pace (individual racecard))
  // then we should not update the url.

  // skip navigation for any excluded routes
  if (excludedRoutes.includes(route?.name)) return;
  if (
    excludedOnlyForCondition.some(
      (exRoute) =>
        exRoute.name === route?.name && exRoute.condidtion(route?.params)
    )
  )
    return;

  const paramKey = includedRoutes[route.name]?.key;
  const paramValue = route.params?.[paramKey];
  const defaultTab = includedRoutes[route.name]?.default;
  const validParamValue = includedRoutes[route?.name]?.validate(paramValue);

  // if the param is not valid, remove it from the url
  // e.g. /racecards/x -> /racecards
  if (!validParamValue && paramValue) {
    delete route?.params?.[paramKey];
    return {
      screen: route.name,
      params: route.params,
    };
  }

  // if the tab is the default tab, remove param from url, e.g.
  // /racecards/today -> /racecards
  if (tabName == defaultTab) {
    // Using Object.assign to create a shallow copy of route.params
    const params = Object.assign({}, route?.params);
    delete params[paramKey];

    return {
      screen: route.name,
      params: {
        ...params,
      },
    };
  }

  // if the tab is not the default tab, update the param in the url, e.g.
  // /racecards/today -> /racecards/tomorrow
  return {
    screen: route.name,
    params: {
      ...route.params,
      [paramKey]: tabName,
    },
  };
};
