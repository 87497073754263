import { Platform } from "react-native";

export const convertToUppercaseTags = (input) => {
  const regex = /<\/?(\w+)/g; // Matches start tags
  const regexClose = /\/(\w+)>/g; // Matches close tags
  let result = input.replace(regex, (match) => {
    // Capitalizes the first letter of the tag and combines it with the rest of the match
    return match[0] + match.slice(1).charAt(0).toUpperCase() + match.slice(2);
  });
  result = result.replace(regexClose, (match) => {
    // Capitalizes the first letter of the closing tag and combines it with the rest of the match
    return "/" + match[1].charAt(0).toUpperCase() + match.slice(2);
  });

  console.log("convertToUppercaseTags result: ", result);
  return result;
};

export function getIndicesOf(searchStr, str, caseSensitive) {
  var searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
    return [];
  }
  var startIndex = 0,
    index,
    indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}

export const removeSpacesFromJSX = (input) => {
  if (!input) return;
  // Remove leading and trailing whitespace from each line
  let cleanedInput = input.replace(/^\s+|\s+$/gm, "");

  // Remove line breaks
  // cleanedInput = cleanedInput.replace(/\n/g, "");

  // Remove the spaces around the tags
  cleanedInput = cleanedInput.replace(/>\s+</g, "><");

  return cleanedInput;
};

export const extractScripts = (capitalizedHtml: string) => {
  // append shit to dom
  const openingScriptTags = getIndicesOf("<script", capitalizedHtml, false);
  const closingScriptTags = getIndicesOf("</script>", capitalizedHtml, false);
  const scriptstoRunInNativeEnv = [];
  const matchingScriptTags =
    openingScriptTags?.length === closingScriptTags?.length;
  if (matchingScriptTags) {
    if (!openingScriptTags.length) return; //guard

    for (let scriptTagIndex in openingScriptTags) {
      // scriptTagIndex = 0,1,2,3,4,5
      const openIndex = openingScriptTags[scriptTagIndex];
      const closingIndex = closingScriptTags[scriptTagIndex];
      const scriptTag = capitalizedHtml.slice(
        openIndex, // content after opening tag
        closingIndex - 1 //const before closing tag
      );

      // maybe refactor later, this is not pretty
      const splitIt = scriptTag.split(" ");
      const source = splitIt.find((s) => s.startsWith("src")).replace("", "");
      const removeSrcString = source.replace("src=", "");
      const length = removeSrcString.length;
      const hasTrailingQuote = removeSrcString.endsWith('"');
      const removeExtraQuotes = hasTrailingQuote
        ? removeSrcString.slice(1, length - 1)
        : removeSrcString.slice(1, length);

      scriptstoRunInNativeEnv.push(
        `<script src=${removeExtraQuotes} ${
          splitIt.includes("defer") ? "defer" : ""
        }
        ${splitIt.includes("async") ? "async" : ""}></script>`
      );
      // }
    }
  }
  return scriptstoRunInNativeEnv;
};

export const decodeAndCapitalize = (html, setScripts = undefined) => {
  const entitiesMap = {
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"', // new entity to replace
  };

  // Correct quotes for the metadata attribute
  let correctedMetadata = html?.replace(
    /metadata="([^"]+)"/g,
    function (_, p1) {
      return "metadata='" + p1 + "'";
    }
  );

  // Correct quotes for the content attribute
  let correctedContent = correctedMetadata?.replace(
    /content="([^"]+)"/g,
    function (_, p1) {
      return "content='" + p1 + "'";
    }
  );

  // Correct quotes for the options attribute
  let correctedOptions = correctedContent?.replace(
    /options="([^"]+)"/g,
    function (_, p1) {
      return "options='" + p1 + "'";
    }
  );

  // First replace ampersands
  let decodedHtml = correctedOptions.replace(/&amp;/g, function (entity) {
    if (entity === "&amp;") return "&";
    return entity;
  });

  // Replace other HTML entities
  decodedHtml = decodedHtml.replace(/&[a-z]+;/g, function (entity) {
    return entitiesMap[entity] || entity;
  });

  // Check if <pre> tags need to be added
  if (decodedHtml?.includes("<RHtml content='<pre></pre>'>")) {
    // Add <pre> tags to the content

    decodedHtml = decodedHtml.replace(
      /<RHtml[^>]*content=['"]<pre><\/pre>['"][^>]*>([^<]+)<\/RHtml>/g,
      function (_, p1) {
        return `<RHtml><Pre>${p1
          .replace(/&gt;/g, ">")
          .replace(/&lt;/g, "<")}</Pre></RHtml>`;
      }
    );
  }

  // replace any internal quotes within the caption attribute with typographical quotes (“”)
  decodedHtml = decodedHtml.replace(/caption="([^"]+?)"/g, function (_, p1) {
    // Use a flag to alternate between opening and closing quotes
    let isOpeningQuote = true;
    return (
      'caption="' +
      p1.replace(/&quot;/g, function () {
        if (isOpeningQuote) {
          isOpeningQuote = false;
          return "“"; // Opening typographic quote
        } else {
          isOpeningQuote = true;
          return "”"; // Closing typographic quote
        }
      }) +
      '"'
    );
  });

  // Remove 'content' attribute and close 'RHtml' tag
  let cleanedHtml = decodedHtml.replace(
    /<RHtml content='([^']+)'>/g,
    function (_, p1) {
      return "<RHtml>" + p1;
    }
  );

  // // Capitalize the first letter of each tag
  let capitalizedHtml = cleanedHtml.replace(
    /(<\/?)(\w+)/g,
    function (_, p1, p2) {
      //do not capitalize tags within a blockquote, otherwise tweets will not render
      if (
        p2 === "blockquote" ||
        p2 === "a" ||
        p2 === "p" ||
        p2 === "br" ||
        p2 === "script" ||
        p2 === "div"
      )
        return p1 + p2;
      return p1 + (p2.charAt(0).toUpperCase() + p2.slice(1));
    }
  );

  return capitalizedHtml;
};

// // Regular expression pattern to match the tweet ID
export const extractTweetId = (input) => {
  const pattern = /https:\/\/twitter\.com\/\w+\/status\/(\d+)/;
  const match = input.match(pattern);

  if (match) {
    const tweetID = match[1];
    return tweetID;
  } else {
    return null;
  }
};

export const detectInvalidTags = (content) => {
  const knownComponents = [
    "RHtml",
    "RCaption",
    "RText",
    "RDate",
    "RAttachment",
    "View",
    "RBold",
    "Bold",
    "RItalic",
    "RStrikeThrough",
    "RLink",
    "RParagraph",
    "RBlockQuote",
    "RHeading",
    "RHorizontalRule",
    "RListOrdered",
    "RListUnordered",
    "RListItem",
    "Pre",
  ];

  // This regex will match JSX component names
  const regex = /<([a-zA-Z][a-zA-Z0-9]*?)[\s/>]/g;

  // Use a Set to keep track of component names we find
  const componentNames = new Set();

  // Find all component names in the JSX string
  let match;
  while ((match = regex.exec(content)) !== null) {
    componentNames.add(match[1]);
  }

  // Find the component names that are not in knownComponents
  const unidentifiedComponents = [...componentNames].filter(
    (name) => !knownComponents.includes(name)
  );

  if (unidentifiedComponents.length > 0) {
    throw new Error(
      "*** unidentified components found: " + unidentifiedComponents.join(", ")
    );
  } else {
    console.log("************* All components identified");
  }
};
