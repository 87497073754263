import React, { useState } from "react";
import { FlatList, View, ViewStyle, FlatListProps } from "react-native";
import { useScrollControl } from "../../hooks/useScrollControl";

type Props<T> = FlatListProps<T> & {
  elementDistance: number;
  style?: ViewStyle;
  childrenWidth: number;
  hideArrows?: boolean;
  scrollRef?: React.RefObject<FlatList>;
  data: T[];
};

const RFlatList = <T extends {}>({
  elementDistance,
  style,
  childrenWidth,
  hideArrows,
  scrollRef,
  data,
  ...rest
}: Props<T>) => {
  const [parentWidth, setParentWidth] = React.useState<number>();

  const {
    handleLayout,
    handleHideArrow,
    hideLeftArrow,
    hideRightArrow,
    setPosition,
    ScrollIndicator,
  } = useScrollControl({
    scrollRef,
    elementDistance,
    childrenWidth,
    parentWidth,
  });

  if (rest.horizontal) {
    return (
      <View style={style}>
        {!hideArrows && !hideLeftArrow && <ScrollIndicator type="left" />}
        <FlatList
          {...rest}
          ref={scrollRef}
          data={data}
          onScroll={({ nativeEvent }) => {
            setPosition(nativeEvent.contentOffset.x);
            handleHideArrow(nativeEvent);
          }}
          horizontal
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={400}
        />
        {!hideArrows && !hideRightArrow && <ScrollIndicator type="right" />}
      </View>
    );
  } else {
    return <FlatList style={style && style} {...rest} data={data} />;
  }
};

export default React.memo(RFlatList);
