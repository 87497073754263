import { BASE_URL, fetchGet, formatQueryParams } from "..";
import { Advert } from "../../types/advert";
import { APIResponse } from "../../types/responses/APIResponse";
import type { AdPlacements, AdvertDetails } from "../../types/advert";
import { storage, STORAGE_KEYS } from "../../utils/storage";
import { cleanSlug } from "../../utils/cleanSlug";
import { YYYYMMDD, today } from "../../utils/TimeCalculator";
import { Linking, Platform } from "react-native";
import { userAgentIsSSR } from "../../utils/userAgent";

const possiblePlacements = [
  "overlay_primary",
  "overlay_secondary",
  "skyscraper",
  "carousel",
  "header",
  "footer",
  "banner",
  "tab",
  "takeover",
];

const todayKey = YYYYMMDD(new Date());
const freshAdObject = { [todayKey]: {} };

export const getAdverts = async (
  path: string,
  placement?: AdPlacements
): Promise<APIResponse<Advert>> => {
  if (!path) return;
  let formattedPlacements;
  if (!placement) {
    formattedPlacements = possiblePlacements
      .map((p) => encodeURIComponent(`placements[]`) + `=` + `${p}`)
      .join("&");
  } else {
    formattedPlacements =
      encodeURIComponent(`placements[]`) + `=` + `${placement}`;
  }

  // also send through impressions and daily_impressions, ensuring they're set to something.
  let seenAdverts = freshAdObject;
  try {
    seenAdverts = await storage.load({
      key: STORAGE_KEYS.SEEN_ADVERTS,
    });
    if (!seenAdverts[todayKey]) throw Error();
  } catch (e) {
    await storage.save({
      key: STORAGE_KEYS.SEEN_ADVERTS,
      data: freshAdObject,
    });
    seenAdverts = freshAdObject;
  }

  let seenOverlayAdverts = freshAdObject;
  try {
    seenOverlayAdverts = await storage.load({
      key: STORAGE_KEYS.SEEN_OVERLAY_ADVERTS,
    });
    if (!seenOverlayAdverts[todayKey]) throw Error();
  } catch (e) {
    await storage.save({
      key: STORAGE_KEYS.SEEN_OVERLAY_ADVERTS,
      data: freshAdObject,
    });
    seenOverlayAdverts = freshAdObject;
  }

  let seenTakeoverAdverts = freshAdObject;
  try {
    seenTakeoverAdverts = await storage.load({
      key: STORAGE_KEYS.SEEN_TAKEOVER_ADVERTS,
    });
    if (!seenTakeoverAdverts[todayKey]) throw Error();
  } catch (e) {
    await storage.save({
      key: STORAGE_KEYS.SEEN_TAKEOVER_ADVERTS,
      data: freshAdObject,
    });
    seenTakeoverAdverts = freshAdObject;
  }

  // check for any old keys, and delete
  Object.keys(seenAdverts).forEach(async (key) => {
    let foundDeadKey = false;
    if (key !== todayKey) {
      foundDeadKey = true;
      delete seenAdverts[key];
    }
    if (foundDeadKey) {
      await storage.save({
        key: STORAGE_KEYS.SEEN_ADVERTS,
        data: seenAdverts,
      });
    }
  });

  Object.keys(seenOverlayAdverts).forEach(async (key) => {
    let foundDeadKey = false;
    if (key !== todayKey) {
      foundDeadKey = true;
      delete seenOverlayAdverts[key];
    }
    if (foundDeadKey) {
      await storage.save({
        key: STORAGE_KEYS.SEEN_OVERLAY_ADVERTS,
        data: seenOverlayAdverts,
      });
    }
  });

  Object.keys(seenTakeoverAdverts).forEach(async (key) => {
    let foundDeadKey = false;
    if (key !== todayKey) {
      foundDeadKey = true;
      delete seenTakeoverAdverts[key];
    }
    if (foundDeadKey) {
      await storage.save({
        key: STORAGE_KEYS.SEEN_TAKEOVER_ADVERTS,
        data: seenTakeoverAdverts,
      });
    }
  });

  const formatParams = (
    mainKey: string,
    adverts: { [key: string]: string }
  ) => {
    return Object.keys(adverts).map((key) => {
      return encodeURIComponent(`${mainKey}[${key}]`) + `=` + `${adverts[key]}`;
    });
  };

  const advertsString = formatParams("impressions", seenAdverts[todayKey]);
  const overLayAdvertsString = formatParams(
    "overlay_impressions",
    seenOverlayAdverts[todayKey]
  );
  const takeOverAdvertsString = formatParams(
    "takeover_impressions",
    seenTakeoverAdverts[todayKey]
  );

  const url = `member/advertising${path.toLowerCase()}?${formattedPlacements}&${advertsString.join(
    "&"
  )}&${overLayAdvertsString.join("&")}&${takeOverAdvertsString.join("&")}`;

  return await fetchGet(url);
};

export const advertClick = async (link_url: string) => {
  // this can fail if the advert doesn't have a link_url, and block
  // a subsequent valid link_url from opening.
  // returning here will allow the later valid link_url to open.
  if (!link_url) return;

  Linking.openURL(BASE_URL + cleanSlug(link_url));
};

export const saveAdvertSeen = async (advertsForPage: Advert) => {
  try {
    await incrementAdvertImpressions(advertsForPage);
  } catch (e) {
    await storage.save({
      key: STORAGE_KEYS.SEEN_ADVERTS,
      data: freshAdObject,
    });
    await storage.save({
      key: STORAGE_KEYS.SEEN_OVERLAY_ADVERTS,
      data: freshAdObject,
    });
    await incrementAdvertImpressions(advertsForPage);
  }
};

// see the corresponding endpoint here: https://github.com/onpace/racing-tv-rails/blob/bug_fixes/api/app/controllers/member/advertising_controller.rb#L9
// this function is called when the page is loaded, and increments the impressions for each advert on the page
// it also increments the impressions for each overlay advert on the page
// it does this by checking the adverts that have been seen on this page today, and incrementing them if they haven't been seen yet
const incrementAdvertImpressions = async (advertsForPage: Advert) => {
  let seenAdverts = await storage.load({
    key: STORAGE_KEYS.SEEN_ADVERTS,
  });

  let seenOverlayAdverts = await storage.load({
    key: STORAGE_KEYS.SEEN_OVERLAY_ADVERTS,
  });

  let seenTakeoverAdverts = await storage.load({
    key: STORAGE_KEYS.SEEN_TAKEOVER_ADVERTS,
  });

  // increment any placements where we're actually showing them on the page
  let seenOnCurrentPage = [];
  let seenOverlaysOnCurrentPage = [];
  possiblePlacements.map(async (placement) => {
    const advertKey = placement === "takeover" ? "takeover_id" : "campaign_id";
    // skip past any placements that don't show on this path (i.e skyscrapers dont show on home)
    if (notShowingAd(advertsForPage.path, placement)) return;

    const individualAdvert = advertsForPage.placements[placement];

    // skip past the null placements
    if (!individualAdvert) return;

    // increment advert impressions if we haven't seen it on the page yet
    if (seenOnCurrentPage.includes(individualAdvert[advertKey])) return;
    seenAdverts[todayKey][individualAdvert[advertKey]] =
      seenAdverts[todayKey][individualAdvert[advertKey]] + 1 || 1;
    seenOnCurrentPage.push(individualAdvert[advertKey]);

    // increment overlay adverts if we haven't seen them on the page yet
    if (placement === "overlay_primary" || placement === "overlay_secondary") {
      if (seenOverlaysOnCurrentPage.includes(individualAdvert.campaign_id))
        return;
      seenOverlayAdverts[todayKey][individualAdvert.campaign_id] =
        seenOverlayAdverts[todayKey][individualAdvert.campaign_id] + 1 || 1;
      seenOverlaysOnCurrentPage.push(individualAdvert.campaign_id);
    }

    if (placement === "takeover") {
      // we also need to increment the takeover adverts
      seenTakeoverAdverts[todayKey][individualAdvert.takeover_id] =
        seenTakeoverAdverts[todayKey][individualAdvert.takeover_id] + 1 || 1;
    }
  });
  // debugger;
  // update storage
  await storage.save({
    key: STORAGE_KEYS.SEEN_ADVERTS,
    data: seenAdverts,
  });

  await storage.save({
    key: STORAGE_KEYS.SEEN_OVERLAY_ADVERTS,
    data: seenOverlayAdverts,
  });

  await storage.save({
    key: STORAGE_KEYS.SEEN_TAKEOVER_ADVERTS,
    data: seenTakeoverAdverts,
  });
};

// detects whether a page actually can show an advert or not, i.e skyscrapers don't show on home
const notShowingAd = (path: string, placement: string) => {
  // if it's SSR
  if (userAgentIsSSR()) return true;

  // if its home, don't log any skyscraper ads since homepage doesn't have them.
  if (path == "/home" && ["skyscraper", "header"].includes(placement)) {
    console.log("ads: skipping log on skyscraper ads");
    return true;
  }

  // if its not home, don't log any carousel ads since there's no carousel ads in main layout
  if (path != "/home" && placement === "carousel") {
    console.log("ads: skipping log on carousel ads");
    return true;
  }

  // we also only show skyscraper ads on main layout when its over 1200px wide.
  if (
    placement === "skyscraper" &&
    window?.innerWidth &&
    window?.innerWidth < 1200
  ) {
    console.log("ads: skipping log on skyscraper ads");

    return true;
  }

  if (placement === "tab") {
    // wide and web, we don't show tab
    if (Platform.OS == "web" && window?.width >= 992) {
      console.log("ads: skipping log on tab ads");
      return true;
    }

    // assume we'll always show tab on mobile.
    if (Platform.OS != "web") {
      return false;
    }
  }

  return false;
};
