import { View } from "react-native";
import { pressReplayParams } from "../../../../../utils/nav";
import { RLink } from "../../../../RLink/RLink";
import RText from "../../../../RText";
import variables from "../../../../../styles/variables";
import { Tip as TipT } from "../../../../../types/tipster";
import { useAtom } from "jotai";
import { currentRouteAtom } from "../../../../../atoms";

const { spacing } = variables;

type Props = {
  tip: TipT;
};

const TipComment = ({ tip }: Props) => {
  const [currentRoute] = useAtom(currentRouteAtom);

  function addFullStop(comment: string) {
    return comment[comment.length - 1] !== "." ? comment + "." : comment;
  }

  // if the tip is displayed in a news article, then don't show the tip description since the tip is already explained in the article
  if (currentRoute === "NewsPost") return;

  return (
    <View style={{ marginHorizontal: spacing.xxsmall }}>
      {tip.description && (
        <RText style={{ flexWrap: "wrap" }}>
          {addFullStop(tip.description)}{" "}
        </RText>
      )}
      {!!tip.runner.last_replay && (
        <RLink
          to={{ ...pressReplayParams(tip.runner.last_replay) }}
          shouldOpenInNewTab
          size="sm"
        >
          Watch last run
        </RLink>
      )}
      {tip.expert_eye_comment && (
        <RText style={{ marginTop: spacing.xxsmall }}>
          {addFullStop(tip.expert_eye_comment)}{" "}
        </RText>
      )}
    </View>
  );
};

export default TipComment;
