import React, { useEffect, useRef, useState } from "react";
import {
  LayoutRectangle,
  Platform,
  StyleSheet,
  View,
  Pressable,
} from "react-native";
import { useCloseFromOutside } from "../../hooks/useCloseFromOutside";
import { useResponsive } from "../../hooks/useResponsive";
import { parseSecondsInMinutes } from "../../utils/TimeCalculator";
import Icon from "../Icon";
import RText from "../RText";
import LiveChannel from "./LiveChannel";
import ProgressBar from "./ProgressBar";
import VolumeControl from "./VolumeControl";
import variables from "../../styles/variables";
import { useAtom } from "jotai";
import {
  isMutedAtom,
  liveStreamFullScreenAtom,
  loadableLiveChannelsAtom,
} from "../../atoms";

export type ControlBarProps = {
  timer: {
    currentTime: number;
    duration: number;
  };
  isPlaying: boolean;
  isFullScreen: boolean;
  isLive?: boolean;
  isAdPlaying?: boolean;
  isFocused?: boolean;
  castButtonProps: {
    currentSource: string;
    title: string;
    subtitle: string;
    poster: string;
    contentType: string;
  };
  onVolumeChange: (volume: number) => void;
  onMutePress: (isMuted: boolean) => void;
  onPlayPausePress?: () => void;
  onFullScreenPress?: () => void;
  onSeek?: (seconds: number) => void;
  onPopOutPress?: () => void;
  onPopupOnpen?: () => void;
  onHover?: () => void;
};

const isWeb = Platform.OS === "web";

const ControlBar: React.FC<ControlBarProps> = ({
  timer,
  isFocused,
  isPlaying,
  isFullScreen,
  isLive,
  isAdPlaying,
  castButtonProps,
  onVolumeChange,
  onMutePress,
  onPlayPausePress,
  onFullScreenPress,
  onSeek,
  onPopOutPress,
  onPopupOnpen,
  onHover,
}) => {
  const { isMediumAndDown, isLargeAndUp } = useResponsive();
  const [liveChannels] = useAtom(loadableLiveChannelsAtom);

  const [displayVolumeControl, setDisplayVolumeControl] = useState(false);
  const [displayLiveChannel, setDisplayLiveChannel] = useState(false);
  const [volumeHovered, setVolumeHovered] = useState(false);
  const [liveChannelHovered, setLiveChannelHovered] = useState(false);
  const [liveChannelButtonSize, setLiveChannelButtonSize] =
    useState<LayoutRectangle>();

  const closeMenusRef = useCloseFromOutside(
    displayVolumeControl,
    setDisplayVolumeControl
  );
  const closeLiveChannelRef = useCloseFromOutside(
    displayLiveChannel,
    setDisplayLiveChannel
  );
  const { currentTime, duration } = timer;
  const currentTimeFormatted = parseSecondsInMinutes(currentTime || 0);
  const durationFormatted = parseSecondsInMinutes(duration || 1);
  // const [isMuted, setIsMuted] = useState(false);
  const [isMuted, setIsMuted] = useAtom(isMutedAtom);
  const refContainer = useRef(null);
  const [_, setLiveStreamFullScreen] = useAtom(liveStreamFullScreenAtom);

  const hoverEventListenerHandler = (e) => {
    onHover?.();
  };

  useEffect(() => {
    if (isLive) {
      if (isFocused) setIsMuted(false);
      else setIsMuted(true);
    }
  }, [isFocused]);

  useEffect(() => {
    if (displayLiveChannel || displayVolumeControl) {
      onPopupOnpen?.();
    }
  }, [displayLiveChannel, displayVolumeControl]);

  useEffect(() => {
    if (volumeHovered) setDisplayVolumeControl(true);
    else setDisplayVolumeControl(false);
  }, [volumeHovered]);

  useEffect(() => {
    if (liveChannelHovered) setDisplayLiveChannel(true);
    else setDisplayLiveChannel(false);
  }, [liveChannelHovered]);

  useEffect(() => {
    onMutePress(isMuted);
  }, [isMuted]);

  useEffect(() => {
    if (!isWeb) return;
    refContainer.current?.addEventListener(
      "mousemove",
      hoverEventListenerHandler
    );
    return () => {
      refContainer.current?.removeEventListener(
        "mousemove",
        hoverEventListenerHandler
      );
    };
  }, [refContainer]);

  const handleLiveChannel = () => {
    setDisplayLiveChannel((prev) => !prev);
  };

  return (
    <View
      ref={refContainer}
      style={[
        styles.container,
        {
          zIndex: 2147483647,
        },
      ]}
    >
      <Pressable style={styles.playPause} onPress={() => onPlayPausePress?.()}>
        <Icon
          name={isPlaying ? "pauseVideo" : "playVideo"}
          fill="palette.rtv.white"
          size={isFullScreen ? "medium" : "small"}
        />
      </Pressable>
      {!isLive && !isAdPlaying && durationFormatted !== "00:01" && (
        <View style={[styles.timer, isLargeAndUp && { flex: 2, minWidth: 90 }]}>
          <RText
            size={isMediumAndDown && !isFullScreen ? "xs" : "sm"}
            color="white"
          >{`${currentTimeFormatted} / ${durationFormatted}`}</RText>
        </View>
      )}
      <View style={[styles.progressBar, isLargeAndUp && { flex: 12 }]}>
        {!isLive && (
          <ProgressBar
            isAdPlaying={isAdPlaying}
            timer={timer}
            onSeek={(seconds) => onSeek?.(seconds)}
            isLive={isLive}
          />
        )}
      </View>
      <View ref={closeMenusRef} style={styles.volume}>
        <Pressable
          // @ts-ignore
          onMouseEnter={() => setVolumeHovered(true)}
          onMouseLeave={() => setVolumeHovered(false)}
          onPress={() => setIsMuted((prev) => !prev)}
          style={styles.pressable}
        >
          <Icon
            name={isMuted ? "mutedVolume" : "volume"}
            fill="palette.rtv.white"
            size={isFullScreen ? "medium" : "small"}
          />
          <VolumeControl
            onVolumeChange={(volume) => {
              onVolumeChange(volume);
              setIsMuted(volume === 0);
            }}
            isVisible={displayVolumeControl}
            isMuted={isMuted}
          />
        </Pressable>
      </View>
      {isWeb && (
        <Pressable style={styles.popout} onPress={() => onPopOutPress?.()}>
          <Icon
            name="popout"
            fill="palette.rtv.white"
            size={isFullScreen ? "medium" : "small"}
          />
        </Pressable>
      )}
      {isLive && liveChannels.state === "hasData" && (
        <View
          onLayout={(e) => setLiveChannelButtonSize(e.nativeEvent.layout)}
          ref={closeLiveChannelRef}
          style={styles.liveChannel}
        >
          <Pressable
            // @ts-ignore
            onMouseEnter={() => setLiveChannelHovered(true)}
            onMouseLeave={() => setLiveChannelHovered(false)}
            onPress={handleLiveChannel}
            style={styles.pressable}
          >
            <Icon
              name="rtvLogo"
              fill="palette.rtv.white"
              size={isFullScreen ? "medium" : "small"}
            />
            <LiveChannel
              parentSize={liveChannelButtonSize}
              isVisible={displayLiveChannel}
              onSelectChannel={() => setDisplayLiveChannel(false)}
            />
          </Pressable>
        </View>
      )}
      <Pressable
        style={styles.fullScreen}
        onPress={() =>
          isLive && isWeb
            ? setLiveStreamFullScreen((prev) => !prev)
            : onFullScreenPress?.()
        }
      >
        <Icon
          name={isFullScreen ? "exitFullScreen" : "enterFullScreen"}
          fill="palette.rtv.white"
          size={isFullScreen ? "medium" : "small"}
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: variables.colors.palette.rtv.primary,
    minHeight: isWeb ? variables.spacing.large : variables.spacing.small,
    maxHeight: isWeb ? variables.spacing.large : variables.spacing.medium,
    width: "100%",
    paddingLeft: variables.spacing.xxsmall,
    paddingRight: variables.spacing.xxsmall,
  },
  playPause: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: variables.spacing.medium,
  },
  timer: {
    flex: 3,
    justifyContent: "center",
    alignItems: "center",
    minWidth: variables.spacing.large,
  },
  progressBar: {
    flex: 5,
    marginHorizontal: variables.spacing.xsmall,
    minWidth: variables.spacing.xsmall,
  },
  volume: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: variables.spacing.medium,
  },
  popout: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: variables.spacing.medium,
  },
  liveChannel: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: variables.spacing.medium,
  },
  fullScreen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: variables.spacing.medium,
  },
  pressable: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ControlBar;
