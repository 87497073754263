import React, { useCallback, useEffect, useState } from "react";
import * as Updates from "expo-updates";
import {
  Modal,
  Platform,
  View,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";
import RText from "../components/RText";
import variables from "../styles/variables";
import LoadingSpinner from "../components/LoadingSpinner";
import Gap from "../components/Gap";
import Constants from "expo-constants";
import { FRONTEND_URL, fetchGet } from "../client";
import Bugsnag from "@bugsnag/expo";

const storeUrls = {
  android:
    "https://play.google.com/store/apps/details?id=com.racinguk.app&hl=en&gl=GB",
  ios: "https://apps.apple.com/gb/app/racing-tv-live-horse-racing/id352776768",
};

const isStoreVersionNewer = (storeVersion: string) => {
  return (
    storeVersion.localeCompare(Constants.expoConfig.version, undefined, {
      numeric: true,
    }) === 1
  );
};

const UseUpdates = (showUpdateModal, setShowUpdateModal) => {
  const [loading, setLoading] = useState(false);
  const [storeUpdateAvailable, setStoreUpdateAvailable] =
    useState<boolean>(false);

  // Check app stores for any update
  const checkForStoreUpdate = useCallback(async () => {
    // assumes this endpoint sets a max-age header of 0
    // so updates can be received immediately
    try {
      const res = await fetch(`${FRONTEND_URL}/api/native/version`);
      const latestVersions = await res.json();
      if (!latestVersions) return false; // escape early if no versions returned

      if (isStoreVersionNewer(latestVersions[Platform.OS])) {
        setStoreUpdateAvailable(true);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      const error = new Error(`Error checking for app store updates: ${e}`);
      Bugsnag.notify(error);

      return false;
    }
  }, [FRONTEND_URL, Platform.OS]);

  // Show modal if store or OTA update is available
  const checkForUpdateAsync = async () => {
    try {
      // below will always fail on simulator, so just set to false if
      // you want to test the above locally.
      const OTAUpdate = await Updates.checkForUpdateAsync();
      const storeUpdateAvailable = await checkForStoreUpdate();

      if (storeUpdateAvailable || OTAUpdate?.isAvailable)
        setShowUpdateModal(true);
    } catch (e) {
      const error = new Error(`Error checking for updates: ${e}`);
      Bugsnag.notify(error);
    }
  };

  const applyOTAUpdate = async () => {
    try {
      setLoading(true);
      await Updates.fetchUpdateAsync();
      await Updates.reloadAsync();
    } catch (error) {
      console.log(`Error fetching updates: ${error}`);
    } finally {
      setLoading(false);
      setShowUpdateModal(false);
    }
  };

  const openStore = () => {
    const storeUrl = storeUrls[Platform.OS];

    Linking.canOpenURL(storeUrl).then(
      (supported) => supported && Linking.openURL(storeUrl)
    );
  };

  const updateModal = () => {
    return (
      <Modal
        style={styles.modal}
        animationType="slide"
        transparent={true}
        visible={showUpdateModal}
      >
        {/* overlay */}
        <View style={styles.overlay} />
        <View style={styles.container}>
          <RText
            weight="semiBold"
            size="lg"
            color="white"
            style={{ width: "100%", textAlign: "center" }}
          >
            There's an update available! 🎉
          </RText>
          <Gap vertical size={"small"} />
          <RText weight="semiBold" size="md" color="white">
            To keep using the app, please update now
          </RText>
          <Gap vertical size={"large"} />
          {loading ? (
            <View style={styles.loadingContainer}>
              <LoadingSpinner />
            </View>
          ) : (
            <TouchableOpacity
              onPress={storeUpdateAvailable ? openStore : applyOTAUpdate}
              style={styles.contentContainer}
            >
              <RText size="md_lg" color="white">
                Update
              </RText>
            </TouchableOpacity>
          )}
        </View>
      </Modal>
    );
  };

  return {
    updateModal,
    checkForUpdateAsync,
  };
};

export default UseUpdates;

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    opacity: 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    height: 230,
    width: "90%",
    backgroundColor: variables.colors.palette.rtv.primary,
    borderRadius: 5,
    padding: 20,
    position: "absolute",
    top: "40%",
    alignSelf: "center",
  },
  loadingContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  contentContainer: {
    backgroundColor: variables.colors.palette.rtv.secondary,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginTop: 10,
  },
});
