import React, { FC, useCallback, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Dimensions, Platform } from "react-native";
import RWebView from "../RWebView";
import RModal from "../RModal/RModal";
import {
  AuthModalType,
  cmsDataAtom,
  openAuthModalAtom,
  selectedPlanAtom,
  userAtom,
} from "../../atoms";
import { useResponsive } from "../../hooks/useResponsive";
import { getJwtToken } from "../RWebView/Utils";
import { formatJsonParams, formatQueryParams } from "../../client";
import { getUserState } from "../../utils/auth";

const SMALL_DIMENSION = 50;
const LARGE_DIMENSION = 300;
const MAX_HEIGHT = 1200;

type AuthProps = {
  url: string;
  type: AuthModalType;
};

const Auth: FC<AuthProps> = ({ url, type }) => {
  const [_, setIsAuthModalOpen] = useAtom(openAuthModalAtom);
  const [user] = useAtom(userAtom);
  const userState = getUserState(user);
  const { isSmallAndDown } = useResponsive();
  const { height } = Dimensions.get("window");
  const [cmsData] = useAtom(cmsDataAtom);

  const minDimension = Math.min(
    height - (isSmallAndDown ? SMALL_DIMENSION : LARGE_DIMENSION),
    MAX_HEIGHT
  );

  const containerStyles = {
    justifyContent: "center",
    alignItems: "center",
  };

  const contentStyles = {
    flex: 1,
    width: isSmallAndDown ? "100%" : 500,
    marginBottom: isSmallAndDown ? 0 : "5%",
    minHeight: isSmallAndDown ? minDimension : 0,
    maxHeight: 1000,
    alignSelf: "center",
    backgroundColor: "transparent",
  };

  const closeModal = useCallback(() => setIsAuthModalOpen(undefined), []);

  const [token, setToken] = useState<string | undefined>(undefined);
  const [selectedPlan] = useAtom(selectedPlanAtom);

  useEffect(() => {
    getJwtToken().then((token) => {
      console.log("got token: ", token);
      token && setToken(token);
    });
  }, []);

  let src;

  // don't allow fdp if not enabled.
  if (type == "fdp" && !cmsData?.settings?.free_day_pass_enabled) {
    type = "join";
  }

  // force the user to login if they are on ipad.
  // in the event they are already logged in, we should send
  // them to a page which signposts to upgrade on web.
  // but for now we won't show any flow on ios.
  // TODO - account for free day pass!
  if (Platform.OS === "ios") {
    if (type == "ios_paid") {
      // do nothing
    } else {
      type = "login";
    }
  }

  //
  // fully paid user - pass straight to stage 5 (success) - user has come here from trying to sign up incorrectly
  if (userState == "paid_account" || type == "ios_paid") {
    src = `${url}/join/success`;
  } else if (type == "upgrade") {
    src = `${url}/join/choose-package?`;
    if (token) src += `token=${token}&`;
    if (selectedPlan) src += `plan=${selectedPlan}`;
  } else if (type.plan_type) {
    // got a pre-selected plan
    let plan = type;

    // .. and a user
    if (userState == "free_account") {
      // but invalid/no address, pass to stage 3 (enter address, phone number, activate on sky)
      if (
        !user.address ||
        Object.keys(user.address).length == 0 ||
        user?.address?.address1 == "Address Line 1"
      ) {
        src = `${url}/join/your-details?token=${token}&plan=${formatJsonParams(
          plan
        )}&user=${formatJsonParams({
          detail: user.detail,
          address: user.address,
          free_account: user.free_account,
        })}`;
      }

      // and address, pass to stage 4 (payment)
      else {
        src = `${url}/join/payment?token=${token}&plan=${formatJsonParams(
          plan
        )}&user=${formatJsonParams({
          detail: user.detail,
          address: user.address,
          free_account: user.free_account,
        })}`;
      }
    } else {
      // no user, pass to stage 1 (create account (email, password, consents)) but will skip stage 2 (plan select)
      src = `${url}/join?plan=${formatJsonParams(plan)}`;
    }
  } else if (type == "activate") {
    src = `${url}/fdp/verify?token=${token}`;
  } else {
    src = `${url}/${type}?platform=${formatJsonParams({
      platform: Platform.OS,
    })}`;
  }

  return (
    <RModal
      style={{
        container: containerStyles,
        content: contentStyles,
      }}
      hideCloseBtn={
        // we need to only show cross button on ipad
        !(Platform.OS != "web" && !isSmallAndDown)
      }
      iframeMode
      visible={!!type}
      setVisibility={closeModal}
      authModal
    >
      <RWebView source={src} closeModal={closeModal} />
    </RModal>
  );
};

export default Auth;
