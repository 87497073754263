import { Platform } from "react-native";
import { cleanSlug } from "../../../../../utils/cleanSlug";
import RTouchableOpacity from "../../../../RLink/RTouchableOpacity";
import RText from "../../../../RText";
import {
  findHorseSlugByName,
  findJockeySlugByName,
  findTrainerSlugByName,
} from "../../components/Utils";
import { prepareParams } from "../../../Results/utils";
import { formatDateWithoutDay } from "../../../../../utils/TimeCalculator";
import VideoLink from "../../../../VideoLink/VideoLink";
import { Performance } from "../../../../../types/races/horse";
import { getRegion } from "../../../../../utils/country";

export const CourseButton = ({ track }) => {
  const screen = "Racecourse";
  const params = {
    region: getRegion(track.country_code.iso),
    track: cleanSlug(track.slug),
  };

  return (
    <RTouchableOpacity
      style={{
        minWidth: 100,
        flex: 1,
        paddingTop: Platform.OS === "ios" ? 3 : 0,
      }}
      navScreen={screen}
      navParams={params}
      shouldOpenInNewTab
    >
      <RText weight="semiBold" color="secondary">
        {track.name_3_char}
      </RText>
    </RTouchableOpacity>
  );
};

export const ProfileButton = ({
  type,
  name,
  formRecords,
}: {
  type: string;
  name: string;
  formRecords: Performance[];
}) => {
  const methodMap = {
    jockey: findJockeySlugByName,
    horse: findHorseSlugByName,
    trainer: findTrainerSlugByName,
  };

  return (
    <RTouchableOpacity
      navScreen="IndexOrProfile"
      navParams={{
        type: type,
        slug: cleanSlug(methodMap[type](formRecords, name)),
      }}
      style={{
        minWidth: 100,
        flex: 1,
        paddingTop: Platform.OS === "ios" ? 3 : 0,
      }}
      shouldOpenInNewTab
    >
      <RText weight="semiBold" color="secondary">
        {name}
      </RText>
    </RTouchableOpacity>
  );
};

export const DateButton = ({ data, navScreen = "IndividualResult" }) => {
  const params = prepareParams({
    startTime: data.race_start_time_scheduled || data.race.start_time_scheduled,
    track: cleanSlug(data?.track?.slug || data.race.meeting.track.slug),
  });
  return (
    <RTouchableOpacity
      style={{
        minWidth: 100,
        flex: 1,
        paddingTop: Platform.OS === "ios" ? 3 : 0,
      }}
      navScreen={navScreen}
      navParams={params}
      shouldOpenInNewTab
    >
      <RText weight="semiBold" color="secondary">
        {formatDateWithoutDay(data.meeting_date || data.race.meeting.date)}
      </RText>
    </RTouchableOpacity>
  );
};

export const ReplayButton = ({ data }) => {
  const replayVid = data?.race?.replay_videos[0];
  if (!replayVid) return null;

  return (
    <VideoLink
      race={data}
      videoType={"replay"}
      displayIconOnly={true}
      trackSlug={data?.track?.slug}
      start_time_scheduled={data.race_start_time_scheduled}
      iconSize={"medium"}
    ></VideoLink>
  );
};

export const RaceButton = ({ race }) => {
  const params = prepareParams({
    startTime: race.start_time_scheduled,
    track: race.meeting.track.slug,
  });
  return (
    <RTouchableOpacity
      style={{
        minWidth: 100,
        flex: 1,
        paddingTop: Platform.OS === "ios" ? 3 : 0,
      }}
      navScreen="IndividualRacecard"
      navParams={params}
      shouldOpenInNewTab
    >
      <RText weight="semiBold" color="secondary">
        {race.title?.capitalize()}
      </RText>
    </RTouchableOpacity>
  );
};
