import React from "react";
import { View, StyleSheet } from "react-native";
import variables from "../../styles/variables";
import Icon from "../Icon";

export type IconSize = keyof typeof variables.sizes;

const { colors } = variables;

type Props = {
  size?: "xsmall" | "small" | "medium" | "large";
  color?: "yellow" | "grey";
  borderTopRightRadius?: boolean;
};

const RCorner = ({
  size = "small",
  color = "yellow",
  borderTopRightRadius = false,
}: Props) => {
  const sizeMap = {
    xsmall: {
      iconSize: "xsmall",
      containerSize: 30,
      left: 13.5,
      top: 5.25,
    },
    small: {
      iconSize: "small",
      containerSize: 40,
      left: 18,
      top: 7,
    },
    medium: {
      iconSize: "medium",
      containerSize: 60,
      left: 27,
      top: 10,
    },
    large: {
      iconSize: "xlarge",
      containerSize: 80,
      left: 30,
      top: 10,
    },
  };

  const colorMap = {
    yellow: {
      fill: colors.palette.watchAndBet.yellow,
    },
    grey: {
      fill: colors.palette.neutral.x100,
    },
  };

  return (
    <View
      style={[
        styles.container,
        {
          borderRightWidth: sizeMap[size].containerSize,
          borderBottomWidth: sizeMap[size].containerSize,
          borderRightColor: colorMap[color].fill,
          borderTopRightRadius: borderTopRightRadius ? 3 : 0,
        },
      ]}
    >
      <Icon
        name="rLogo"
        size={sizeMap[size].iconSize as IconSize}
        fill="palette.rtv.primary"
        style={{
          position: "absolute",
          left: sizeMap[size].left,
          top: sizeMap[size].top,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    right: 0,
    borderRightColor: variables.colors.palette.watchAndBet.yellow,
    borderLeftWidth: 0,
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderBottomColor: "transparent",
  },
});

export default RCorner;
