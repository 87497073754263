// Manage FullStory library already loaded in the page through google tag manager

// Declare the FS function that's defined in the FullStory library
declare function FS(command: string, params?: any): void;

const FullStory = {
  init: (orgId: string) => {
    FS("init", {
      orgId,
    });
  },
  setIdentity: (uid: string, data: any) => {
    FS("setIdentity", {
      uid,
      ...data,
    });
  },
};

export default FullStory;
